import localforage from 'localforage';
import logger from '~/services/logger';

export const IS_LOGGED_IN_KEY: string = 'isLoggedIn';
const IS_LOGGED_IN_REVALIDATION_INTERVAL_MS: number = 1000 * 60 * 60 * 24 * 5; // 5 days

export function isLoggedInClientState(): {
  get: () => Promise<boolean | null>;
  set: (value: boolean) => Promise<void>;
  setRevalidate: () => Promise<void>;
  isStale: () => Promise<boolean>;
  remove: () => Promise<void>;
} {
  if (!isClientStorageAvailable()) {
    return {
      get: async () => null,
      set: async () => {},
      setRevalidate: async () => {},
      isStale: async () => true,
      remove: async () => {},
    };
  }

  const versionedIsLoggedInKey: string = getVersionedIsLoggedInKey();
  const isLoggedInRevalidateKey: string = `${versionedIsLoggedInKey}-revalidate`;

  const get = async (): Promise<boolean | null> => {
    try {
      const isLoggedIn: boolean | null = await localforage.getItem(
        versionedIsLoggedInKey
      );
      if (isLoggedIn === null) return null;
      return !!isLoggedIn;
    } catch (error: any) {
      logger.info(`isLoggedInClientState GET: ${error.message}`);
      return null;
    }
  };

  const set = async (value: boolean): Promise<void> => {
    try {
      await localforage.setItem(versionedIsLoggedInKey, value);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(versionedIsLoggedInKey, `${value}`);
      }
    } catch (error: any) {
      logger.info(`isLoggedInClientState SET: ${error.message}`);
    }
  };

  const setRevalidate = async (): Promise<void> => {
    try {
      await localforage.setItem(isLoggedInRevalidateKey, Date.now());
    } catch (error: any) {
      logger.info(`isLoggedInClientState SET REVALIDATE: ${error.message}`);
    }
  };

  const isStale = async (): Promise<boolean> => {
    try {
      const isLoggedInRevalidate: number | null = await localforage.getItem(
        isLoggedInRevalidateKey
      );

      if (isLoggedInRevalidate === null) return true;

      return (
        Date.now() - isLoggedInRevalidate >
        IS_LOGGED_IN_REVALIDATION_INTERVAL_MS
      );
    } catch (error: any) {
      logger.info(`isLoggedInClientState IS STALE: ${error.message}`);
      return true;
    }
  };

  const remove = async (): Promise<void> => {
    try {
      await localforage.removeItem(versionedIsLoggedInKey);
    } catch (error: any) {
      logger.info(`isLoggedInClientState REMOVE: ${error.message}`);
    }
  };

  return {
    get,
    set,
    setRevalidate,
    isStale,
    remove,
  };
}

function isClientStorageAvailable(): boolean {
  try {
    return (
      typeof window !== 'undefined' &&
      typeof window.indexedDB !== 'undefined' &&
      typeof window.localStorage !== 'undefined'
    );
  } catch (e) {
    return false;
  }
}

export function getVersionedIsLoggedInKey(): string {
  const APP_VERSION_UUID: string = window.versionInfo?.APP_VERSION_UUID;
  return `${IS_LOGGED_IN_KEY}-${APP_VERSION_UUID}`;
}
